import React from "react";
import { Link } from "gatsby";

function Sidebar(){
    return(
        <div className="w-full h-full p-2 bg-gray-200 rounded-sm shadow-md" >
            <p className="px-3 py-4 shadow-sm text-green-800 bg-white rounded-sm text-lg font-bold text-center ">Plåt &amp; Smide</p>
            <SidebarLink href="/byggnadssmide" text="Byggnadssmide"/>
            <SidebarLink href="/svetsarbete" text="Svetsarbete"/>
            <SidebarLink href="/tillverkning" text="Tillverkning"/>
            <SidebarLink href="/plasma" text="Plasma bearbetning"/>
            <SidebarLink href="/pallservice" text="Pallservice"/>

            <p className="mt-12 px-3 py-4 shadow-sm text-green-800 bg-white rounded-sm text-lg font-bold text-center ">Övriga tjänster</p>
            <SidebarLink href="/logistik" text="Transport &amp; Logistik"/>
            <SidebarLink href="/montering" text="Montering &amp; Rivning"/>
            <SidebarLink href="/blastring" text="Blästring"/>
            <SidebarLink href="/malning" text="Målning"/>
            <SidebarLink href="/lager" text="Lagerförvaring"/>
            <SidebarLink href="/uthyrning" text="Uthyrning &amp; Bemanning"/>
        </div>
    );
}
export default Sidebar;

function SidebarLink(props){
    return(
        <Link 
            to={props.href} 
            className="my-3 block text-gray-900 hover:bg-white px-3 underline py-3 rounded-sm hover:shadow-lg transform transition-all hover:scale-110"
        >
            <svg xmlns="http://www.w3.org/2000/svg" className="inline-flex w-1.5 h-1.5 mr-3 text-gray-600" fill="currentColor" viewBox="0 0 16 16">
                <circle cx="8" cy="8" r="8"/>
            </svg>
            {props.text}
        </Link>
    )
}

