import React from 'react';
import Layout from '../components/layout';
import MyNavbar from '../components/navbar/navbar';
import Footer from '../components/footer';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Breadcrumb from "../components/breadcrumb";
import Sidebar from '../components/sidebar';
import Cta from "../components/cta";

import favicon from '../images/favicon.ico';

function Uthyrning() {
    return (
        <Layout>
            <Helmet>
                <title>Uthyrning och Bemanning - Danas Plåt &amp; Smide AB</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Vi erbjuder även maskinuthyrning framförallt kompressorer. Vi har också plasmamaskin för uthyrning. Behöver du hyra en Svetsare?" />
                <meta name="keywords" content="Uthyrning,  Bemanning, plasmaskärare, Svetsare, kompressor" />
                <meta property="og:title" content="Uthyrning och Bemanning - Danas Plåt och Smide AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://danasplat.se/uthyrning" />
                <link rel="cannonical" href="https://danasplat.se/uthyrning" />
            </Helmet>

            <MyNavbar active_page="" />
            <section>
                <div className="h-60 md:h-96 w-screen bg-uthyrning bg-top bg-no-repeat bg-cover">
                    <div className="h-60 md:h-96 w-screen  bg-opacity-50 bg-black flex justify-center items-center ">
                        <h1 className="text-4xl sm:text-5xl md:text-7xl font-extrabold text-center text-white">Uthyrning &amp; Bemanning</h1>
                    </div>
                </div>
                <Breadcrumb page="Uthyrning"/>
            </section>

            <section>
                <div className="container mx-auto px-4 pt-24 pb-32">
                    <div className="grid lg:grid-cols-12 gap-24 lg:gap-10">
                        <div className="lg:col-span-9 w-full">
                            <h2 className="text-3xl md:text-4xl lg:text-5xl font-extrabold text-left text-green-900">Bemanning och uthyrning</h2>
                            <p className="lg:text-xl font-bold text-gray-600 mt-12">Uthyrning av kompressor</p>
                            <p className="lg:text-xl text-gray-600">Behöver du hyra en maskin över dagen eller under en längre tid? Danas Plåt &amp; Smide erbjuder även maskinuthyrning framförallt kompressorer som är väl servad. Vi strävar alltid efter att överträffa kundens förväntningar när de hyr kompressorer hos oss. Vi gör det genom att alltid vara tillgängliga,  erbjuda snabb service, och framförallt vara en problemlösare.</p>
                            
                            <p className="lg:text-xl font-bold text-gray-600 mt-6 lg:mt-12">Uthyrning av plasmaskärare</p>
                            <p className="lg:text-xl text-gray-600">Vi har plasmamaskin för uthyrning. Behöver du till exempel återgärda sprickor eller ta bort en svets kan du använda plasmaskäraren för att mejsla med istället för att använda kolbåge.</p>

                            <p className="lg:text-xl font-bold text-gray-600 mt-6 lg:mt-12">Behöver du hyra en Svetsare?</p>
                            <p className="lg:text-xl text-gray-600">Våra Svetsare är välutbildade och har den kompetens, de licenser, prövningar och certifieringar som krävs för att utföra de avancerade svetsuppdragen.</p>
                            <p className="lg:text-xl text-gray-600 mt-6">Våra svetsare utbildas fortlöpande och uppdateras gällande metoder, regler och yrkesspecifik kunskap. Det är viktigt för oss att arbetet och tillverkningen skall ske under säkra former och skall inte påverka medarbetare och arbetsresultat negativt.</p>
                            <p className="lg:text-xl text-gray-600 mt-6">Välkommen att kontakta oss!</p>
                            <Link to="/kontakt" className="block mt-12">
                                <span className="bg-red-700 shadow-xl text-white text-md md:text-xl py-4 px-6 rounded hover:bg-red-800">Kontakta oss</span>
                            </Link>
                        </div>

                        <div className="w-full lg:col-span-3">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>

            <Cta />
            <Footer />
        </Layout>
    );
}
export default Uthyrning;