import React from "react";
import { Link } from "gatsby";

function Cta(){
    return(
        <section className="bg-gradient-to-br from-green-custome to-blue-custome">
            <div className="container mx-auto px-4 py-24">
                <div className="w-full mx-auto lg:w-2/3 xl:w-1/2 flex flex-col items-center justify-center">
                    <p className="text-white text-center">Danas Plåt och Smide AB</p>
                    <h2 className="mt-6 lg:mt-8 text-xl md:text-3xl font-bold text-white text-center">Vi utför alla jobb inom Plåt och Smide små som stora. Allt efter era idéer och önskemål.</h2>
                    <Link to="/kontakt" className="block mt-12 lg:mt-16">
                        <span className="bg-red-700 shadow-xl text-white text-md md:text-xl py-4 px-6 rounded hover:bg-red-800">Kontakta oss</span>
                    </Link>
                </div>
            </div>
        </section>
    );
}
export default Cta;